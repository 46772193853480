(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/national-suspension-message/views/deposit-message.js') >= 0) return;  svs.modules.push('/components/accountservices/national-suspension-message/views/deposit-message.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.accountservices=_svs.accountservices||{};
_svs.accountservices.national_suspension_message=_svs.accountservices.national_suspension_message||{};
_svs.accountservices.national_suspension_message.templates=_svs.accountservices.national_suspension_message.templates||{};
svs.accountservices.national_suspension_message.templates.deposit_message = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"align-left\">\n  <div class=\"bold padding-xs-bottom-1\">Insättning misslyckades</div>\n  <div class=\"padding-xs-bottom\">\n    Du har stängt av dig för spel hos samtliga licensierade spelbolag i Sverige hos Spelinspektionen.\n  </div>\n  <div class=\"padding-xs-bottom\">\n    Se <a class=\"suspension-reminder\" href=\"http://www.spelpaus.se?scos=true\" target=\"_blank\">www.spelpaus.se</a>\n  </div>\n</div>\n";
},"useData":true});
Handlebars.partials['components-accountservices-national_suspension_message-deposit_message'] = svs.accountservices.national_suspension_message.templates.deposit_message;
})(svs, Handlebars);


 })(window);