(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/national-suspension-message/views/create-syndicate-message-new.js') >= 0) return;  svs.modules.push('/components/accountservices/national-suspension-message/views/create-syndicate-message-new.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.accountservices=_svs.accountservices||{};
_svs.accountservices.national_suspension_message=_svs.accountservices.national_suspension_message||{};
_svs.accountservices.national_suspension_message.templates=_svs.accountservices.national_suspension_message.templates||{};
svs.accountservices.national_suspension_message.templates.create_syndicate_message_new = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"align-center\">\n  <div class=\"padding-xs-bottom\">\n    Du har en aktiv spelpaus hos spelpaus.se och kan därför inte spela.\n  </div>\n  <div class=\"padding-xs-bottom\">\n    <a class=\"suspension-reminder\" href=\"http://www.spelpaus.se?scos=true\" target=\"_blank\">www.spelpaus.se</a>\n  </div>\n</div>\n";
},"useData":true});
Handlebars.partials['components-accountservices-national_suspension_message-create_syndicate_message_new'] = svs.accountservices.national_suspension_message.templates.create_syndicate_message_new;
})(svs, Handlebars);


 })(window);