(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/national-suspension-message/views/message.js') >= 0) return;  svs.modules.push('/components/accountservices/national-suspension-message/views/message.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.accountservices=_svs.accountservices||{};
_svs.accountservices.national_suspension_message=_svs.accountservices.national_suspension_message||{};
_svs.accountservices.national_suspension_message.templates=_svs.accountservices.national_suspension_message.templates||{};
svs.accountservices.national_suspension_message.templates.message = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"align-left\">\n  <div class=\"padding-xs-bottom\">\n    Du har stängt av dig för spel hos samtliga licensierade spelbolag i Sverige hos Spelinspektionen.\n  </div>\n  <div class=\"padding-xs-bottom\">\n    Se <a class=\"suspension-reminder\" href=\"http://www.spelpaus.se?scos=true\" target=\"_blank\">www.spelpaus.se</a>\n  </div>\n  <div class=\"padding-xs-bottom padding-xs-top-1\">\n    Du kan ta ut eventuellt saldo från ditt spelkonto från <a href=\""
    + alias3((lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||alias2).call(alias1,"transactionsWithdrawal",{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":9,"column":68},"end":{"line":9,"column":107}}}))
    + "\">\n      Uttagssidan.</a>\n  </div>\n  <div class=\"padding-xs-bottom padding-xs-top-1\">\n    Vid frågor, kontakta <a href=\""
    + alias3((lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||alias2).call(alias1,"customerServiceContact",{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":13,"column":34},"end":{"line":13,"column":73}}}))
    + "\"> Svenska Spels Kundservice.</a>\n  </div>\n</div>";
},"useData":true});
Handlebars.partials['components-accountservices-national_suspension_message-message'] = svs.accountservices.national_suspension_message.templates.message;
})(svs, Handlebars);


 })(window);