(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/national-suspension-message/views/voucher-message.js') >= 0) return;  svs.modules.push('/components/accountservices/national-suspension-message/views/voucher-message.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.accountservices=_svs.accountservices||{};
_svs.accountservices.national_suspension_message=_svs.accountservices.national_suspension_message||{};
_svs.accountservices.national_suspension_message.templates=_svs.accountservices.national_suspension_message.templates||{};
svs.accountservices.national_suspension_message.templates.voucher_message = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"align-left\">\n  <div class=\"bold padding-xs-bottom-1\">Du kan inte lösa in din lott online</div>\n  <div class=\"padding-xs-bottom\">\n    Du har stängt av dig för registrerat spel hos samtliga licenserade bolag i Sverige hos Spelinspektionen. Därför kan du inte lösa in denna lott online. Spel på Svenska Spels skraplotter i butik är dock inte registrerat spel.\n  </div>\n  <div class=\"padding-xs-bottom\">\n    Se <a class=\"suspension-reminder\" href=\"http://www.spelpaus.se?scos=true\" target=\"_blank\">www.spelpaus.se</a>\n  </div>\n</div>\n";
},"useData":true});
Handlebars.partials['components-accountservices-national_suspension_message-voucher_message'] = svs.accountservices.national_suspension_message.templates.voucher_message;
})(svs, Handlebars);


 })(window);